<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("common.general.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("common.general.entries") }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
               <b-form-input
                id="search"
                v-model="searchQuery"
                trim
                :placeholder="$t('common.general.search')"
                class="d-inline-block mr-1"
              />
              <b-button
                variant="primary"
                :to="{ name: 'add-edit-category-document' }"
              >
                <span class="text-nowrap">{{ $t("common.form.add") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPageListTable"
        class="position-relative"
        :items="dataList"
        :fields="tableColumns"
        :busy="isLoadingTable"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('common.general.empty_text')"
      >
        <template #cell(image)="data">
          <b-avatar :src="data.item.image" />
        </template>
        <template #cell(statusCode)="data">
          <b-badge variant="primary">
            {{ data.item.statusCode }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-row>
            <b-col
              cols="12"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              size="sm"
              variant="primary"
              :to="{
                name: 'add-edit-category-document',
                query: { id: data.item.id },
              }"
            >{{ $t("common.form.edit") }}</b-button></b-col>
            <b-col
              cols="12"
              class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-prevent-closing
              block
              size="sm"
              variant="danger"
              @click="onSelectedDelete(data.item)"
            >{{ $t("common.form.delete") }}</b-button></b-col>
          </b-row>
        </template>
        <template #table-busy>
          <TableLoading />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :title="$t('common.form.title_confirm_delete')"
      :ok-title="$t('common.form.delete')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_delete") }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import categoryDocumentServices from '@/services/categoryDocumentServices';
import {
  BAvatar,
  BCard,
  BTable,
  BButton,
  BBadge,
  BCol,
  BRow,
  BModal,
  VBModal,
  BCardText,
  BFormInput,
  BPagination,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import commonServices from '@/services/commonServices';
import TableLoading from '@/components/TableLoading.vue';
export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BTable,
    BButton,
    BBadge,
    BCol,
    BRow,
    vSelect,
    BModal,
    TableLoading,
    BFormInput,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      itemSelected: {},
      itemSelectedDelete: {},
      dataList: [],
      perPage: 10,
      perPageOptions: [10, 20, 50],
      isLoadingTable: false,
      currentPage: 1,
      totalItems: 0,
      searchQuery: '',
      tableColumns: [
        {
          key: 'title',
          label: this.$t('common.general.title'),
          sortable: true,
        },
        {
          key: 'order',
          label: this.$t('common.general.display_order'),
          sortable: true,
        },
        {
          key: 'statusCode',
          label: this.$t('common.general.status_code'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('common.form.actions') },
      ],
    };
  },
  watch: {
    perPage() {
      this.onPagination();
    },
    currentPage() {
      this.onPagination();
    },
    searchQuery() {
      this.onPagination();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoadingTable = true;
      await categoryDocumentServices
        .getList()
        .then((result) => {
          if (result) {
            this.totalRecords = result.data.listCategoryDocuments.items;
            this.dataList = this.totalRecords.slice(
              (this.currentPage - 1) * this.perPage,
              this.perPage,
            );
            this.totalItems = this.totalRecords.length;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingTable = false;
        });
    },
    onFetchData() {
      this.getList();
    },
    onSelectedDelete(item) {
      this.itemSelectedDelete = item;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      await categoryDocumentServices
        .delete(this.itemSelectedDelete.id)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            }
            this.onFetchData();
            commonServices.showSuccess(this, 'msg.success.success');
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn');
            });
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onPagination() {
      const filterList = this.totalRecords.filter((x) => x.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      if (this.searchQuery.length > 0) {
        this.totalItems = filterList.length;
      } else {
        this.totalItems = this.totalRecords.length;
      }
      this.dataList = filterList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
